<template>
  <div class="repay">
    <div class="repay_bg">
      <BaseHeaderBar
        title="Pembayaran"
        :showCustomerService="false"
        :showBackButton="true"
      />
    </div>
    <div class="top-block">
      <div>
        <p class="p1">Yang perlu dibayarkan</p>
        <p class="p2">Rp{{ formatNumberWithCommas(amount) }}</p>
        <p class="p3">Jenis pembayaran：{{ repaymentWay }}</p>
      </div>
    </div>
    <div class="repay-sort-list">
      <div
        v-for="item in bankList"
        :key="item.id"
        @click="selectRepaySort(item)"
      >
        <p>{{ item.name }}</p>
        <img class="arrow-right" src="@/assets/img/arrow-right.png" />
      </div>
    </div>

    <van-popup v-model="showPicker" round position="bottom">
      <div class="popup">
        <div class="picker-toolbar">
          <div @click="showPicker = false" class="picker-cancel">
            <img src="@/assets/img/picker-back-btn.png" />
          </div>
          Pilih metode pembayaran
        </div>
        <div class="repay-sort-picker-list">
          <div
            class="item"
            v-for="item in selectedPayWay.payway"
            :key="item.pay_way"
            @click="goPay(item)"
          >
            <p class="label">{{ item.name }}</p>
            <img class="arrow-right" src="@/assets/img/arrow-right.png" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Popup } from 'vant'
import request from '@/utils/request'

Vue.use(Popup)
const repayTypeMap = {
  1: 'Pelunasan',
  2: 'Perpanjangan'
}

export default {
  name: 'Repay',
  data () {
    return {
      repaymentWay: '',
      amount: '',
      bankList: [],
      selectedPayWay: {},
      showPicker: false
    }
  },
  created () {
    this.getPaymentBankList()
    this.getQuery()
  },
  methods: {
    getPaymentBankList () {
      const postData = {
        redrpaymentType: this.$route.query.repayType
      }
      request.post('padrybankList', postData)
        .then(res => {
          this.bankList = res.padrymentbankList
        })
    },
    getQuery () {
      const { repayType, amount } = this.$route.query
      this.repaymentWay = repayTypeMap[repayType]
      this.amount = amount
    },
    selectRepaySort (item) {
      this.selectedPayWay = item
      this.showPicker = true
    },
    goPay (item) {
      const { repayType, amount, orderId, payID } = this.$route.query
      this.$router.push({
        path: '/pay',
        query: {
          repayType,
          amount,
          orderId,
          payID,
          channelId: this.selectedPayWay.pay_id,
          payChannel: this.selectedPayWay.pay_channel,
          name: this.selectedPayWay.name,
          payWay: item.pay_way,
          payWayName: item.name
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.repay {
  min-height: 100vh;
  background-color: @body-bg-color;
  .repay_bg {
    height: 633px;
    width: 100%;
    background: url("../assets/img/huankuan_bgpng.png") no-repeat;
    background-size: 100% 100%;
  }
}

.top-block {
  margin: 42px 34px;
  background: linear-gradient(20deg, #d391f9 0%, #994df8 100%);
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 20px 30px;

  div {
    p {
      color: #fff;
      font-weight: 400;
      font-size: 28px;
    }

    .p1 {
      margin-bottom: 16px;
    }

    .p2 {
      font-size: 34px;
      font-weight: normal;
      margin-bottom: 16px;
    }
  }
}

.arrow-right {
  width: 46px;
  height: 31px;
}

.repay-sort-list {
  border-radius: 32px;
  margin-left: 35px;
  margin-right: 35px;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 53px;
    height: 106px;
    padding-left: 43px;
    padding-right: 33px;
    margin-bottom: 32px;
    background: #705ac6;

    font-size: 36px;
    font-weight: 600;
    color: #ffffff;
  }
}
.popup {
  background: #47338e;

  .picker-toolbar {
    position: relative;
    width: 100%;
    height: 88px;
    line-height: 88px;
    font-size: 34px;
    color: #fff;
    text-align: center;
    font-weight: 600;
  }

  .picker-cancel {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 30px;
    cursor: pointer;

    img {
      width: 21px;
      height: 34px;
    }
  }

  .repay-sort-picker-list {
    padding: 24px 30px 206px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 98px;
      line-height: 98px;
      padding-left: 40px;
      padding-right: 40px;
      border-radius: 49px;
      background: #7a6cb0;

      &:nth-child(n + 2) {
        margin-top: 18px;
      }

      p {
        font-size: 30px;
        color: #fff;
      }
    }
  }
}
</style>
